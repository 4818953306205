<script setup lang="ts">
import NavBar from "~/components/Navbar/NavBar.vue";
import Header from "~/components/Header/Header.vue";
import NavBarMobile from "~~/components/Navbar/NavBarMobile.vue";
import { useAuthStore } from "~/store/auth/auth.store";

const authStore = useAuthStore();

const { $Localize } = useNuxtApp();

const typedLocalize: any = $Localize;

onMounted(async () => {
  await authStore.fetchProfile();

  if (authStore.isLoggedIn()) {
    typedLocalize.setLanguage(authStore.user?.language);
  }
});
</script>

<template>
  <div class="layout_container">
    <NavBar></NavBar>
    <NavBarMobile></NavBarMobile>
    <div id="right_container" class="right_container">
      <Header></Header>
      <div class="slot relative">
        <slot />
      </div>
    </div>
  </div>
  <FeedbackContainer></FeedbackContainer>
</template>

<style scoped>
.layout_container {
  @apply flex flex-row;
  min-height: 100%;
}

.right_container {
  margin-left: var(--navbar_width);
  overflow-x: hidden;
  width: 100%;
  background: var(--white-main);
  scrollbar-gutter: stable;
}

.slot {
  min-width: 100vw - var(--navbar_width);
  height: calc(100vh - var(--header_height));
}

@media (max-width: 768px) {
  .slot {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .right_container {
    margin-left: 0;
  }
}
</style>
